// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-en-js": () => import("/Users/kay/Local Code/pikonlog/blog/src/templates/blog_en.js" /* webpackChunkName: "component---src-templates-blog-en-js" */),
  "component---src-templates-blog-js": () => import("/Users/kay/Local Code/pikonlog/blog/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-all-en-js": () => import("/Users/kay/Local Code/pikonlog/blog/src/templates/all_en.js" /* webpackChunkName: "component---src-templates-all-en-js" */),
  "component---src-templates-all-js": () => import("/Users/kay/Local Code/pikonlog/blog/src/templates/all.js" /* webpackChunkName: "component---src-templates-all-js" */),
  "component---src-templates-category-en-js": () => import("/Users/kay/Local Code/pikonlog/blog/src/templates/category_en.js" /* webpackChunkName: "component---src-templates-category-en-js" */),
  "component---src-templates-category-js": () => import("/Users/kay/Local Code/pikonlog/blog/src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-tag-en-js": () => import("/Users/kay/Local Code/pikonlog/blog/src/templates/tag_en.js" /* webpackChunkName: "component---src-templates-tag-en-js" */),
  "component---src-templates-tag-js": () => import("/Users/kay/Local Code/pikonlog/blog/src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-pages-404-js": () => import("/Users/kay/Local Code/pikonlog/blog/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-js": () => import("/Users/kay/Local Code/pikonlog/blog/src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-index-js": () => import("/Users/kay/Local Code/pikonlog/blog/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/kay/Local Code/pikonlog/blog/.cache/data.json")

